import { createStyles } from '@mantine/core';

export const useStyles = createStyles(theme => ({
  newsletterSignup: {
    margin: '2rem 0',
    backgroundPosition: 'center center',
    backgroundSize: '100%',
    padding: '1.25rem 0.95rem 3rem',
    backgroundColor: '#f6f5f1',

    [theme.fn.largerThan('lg')]: {
      background: 'transparent url(/content/assets/images/desktop_signup.jpg) no-repeat',
      padding: '1.25rem 3.44rem 3rem',
      width: '100%'
    }
  },

  title: {
    fontSize: '1.7rem',
    fontFamily: 'Agrandir, Arial',
    fontWeight: 'normal',
    color: '#444',
    lineHeight: '2.25rem',
    paddingLeft: '0 !important'
  },

  newsletterForm: {
    [theme.fn.largerThan('sm')]: {
      maxWidth: '70%',
      margin: 'auto'
    },
    [theme.fn.largerThan('lg')]: {
      maxWidth: '50%',
      margin: 'unset'
    }
  },

  newsletterFormWrap: {
    display: 'flex'
  },

  emailInput: {
    width: '70%',
    fontSize: '1.2em',
    borderRadius: '0.25rem 0 0 0.25rem',
    padding: '1.25rem',
    border: 'none',
    '::placeholder': {
      color: '#aeb5c3'
    },
    ':focus': {
      outlineWidth: '0'
    }
  },

  termText: {
    fontSize: '.75rem',
    lineHeight: '0.94rem',
    color: '#282828',
    marginTop: '1rem'
  },

  btnSubmit: {
    padding: '1em',
    width: '30%',
    backgroundColor: '#f04a1c',
    border: 'none',
    borderRadius: '0 0.25rem  0.25rem 0',
    fontSize: '1.2rem',
    fontWeight: 600,
    color: '#fff',
    ':hover': {
      cursor: 'pointer'
    },

    [theme.fn.smallerThan('sm')]: {
      fontSize: '1rem'
    }
  },

  validationError: {
    marginTop: '.5em',
    marginBottom: '.5em',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1em',
    lineHeight: '1em',
    color: '#f04a1c'
  },

  successMessage: {
    maxWidth: '37.5rem',
    padding: '1.25rem'
  },

  successHeading: {
    display: 'flex',
    fontFamily: 'Agrandir, Arial',
    flexDirection: 'column',
    fontWeight: 400,
    fontSize: '1.8rem',
    paddingLeft: '0 !important'
  }
}));
