export interface IconSignOutProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconSignOut = ({
  color,
  size,
  ...props
}: IconSignOutProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 13 14" {...props}>
      <path d="M963.2 247.65L967.0300000000001 243.83L963.2 240L961.97 241.23L963.71 242.95999999999998H958V244.68999999999997H963.71L961.97 246.42999999999998Z " fill="#B1B1B1" fillOpacity="1" transform="matrix(1,0,0,1,-955,-237)" /><path id="SvgjsPath1008" d="M964.53 249.13H956.73V238.73H964.53V237H955.87C955.39 237 955 237.39 955 237.87V250C955 250.48 955.39 250.87 955.87 250.87H964.53Z " fill={color ?? 'currentColor'} transform="matrix(1,0,0,1,-955,-237)" />
    </svg>
  );
};
