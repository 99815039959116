export interface IconHeartProps {
  color?: string;
  size?: number;
}

export const IconHeart = ({
  color,
  size
}: IconHeartProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 20 18" fill="none">
      <path d="M18.0587 1.6032C15.9181 -0.220982 12.7346 0.107137 10.7698 2.13444L10.0003 2.9274L9.23077 2.13444C7.26987 0.107137 4.08243 -0.220982 1.94185 1.6032C-0.51123 3.69692 -0.640134 7.45466 1.55514 9.72415L9.11359 17.5287C9.60186 18.0326 10.3948 18.0326 10.8831 17.5287L18.4415 9.72415C20.6407 7.45466 20.5118 3.69692 18.0587 1.6032Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};
