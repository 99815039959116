export interface IconChevronDownThinProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconChevronDownThin = ({
  color,
  size,
  ...props
}: IconChevronDownThinProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 25 24" {...props} >
      <path d="M18.75 9.99997L17.34 8.58997L12.75 13.17L8.16 8.58997L6.75 9.99997L12.75 16L18.75 9.99997Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};
