export interface IconUserProps {
  color?: string;
  size?: number;
}

export const IconUser = ({
  color,
  size
}: IconUserProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 20 21" fill="none">
      <path d="M10 11.8266C12.75 11.8266 15 9.57662 15 6.82662V5.15662C15 2.40662 12.75 0.156616 10 0.156616C7.25 0.156616 5 2.40662 5 5.15662V6.82662C5 9.57662 7.25 11.8266 10 11.8266Z" fill={color ?? 'currentColor'} />
      <path d="M14.17 13.1566H5.83C2.58 13.1566 0 15.7366 0 18.9866C0 19.4866 0.330002 19.8266 0.830002 19.8266H19.17C19.67 19.8266 20 19.4866 20 18.9866C20 15.7366 17.42 13.1566 14.17 13.1566Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};
