import { createStyles } from '@mantine/core';

interface StyleProps {
  noFullWidthPadding?: boolean;
}

export const useStyles = createStyles((theme, { noFullWidthPadding }: StyleProps) => ({
  container: {
    margin: 'auto',
    padding: '0 1rem'
  },

  md: {
    maxWidth: '1215px',

    [theme.fn.smallerThan(1215)]: noFullWidthPadding && {
      padding: '0'
    }
  },

  lg: {
    maxWidth: '1366px',

    [theme.fn.smallerThan(1366)]: noFullWidthPadding && {
      padding: '0'
    }
  }
}));
