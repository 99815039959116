export interface IconHalfStarProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconHalfStar = ({
  color,
  size,
  ...props
}: IconHalfStarProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 14 14" fill="none" {...props}>
      <path d="M9.97816 7.78245L12.1404 5.67909L9.14523 5.24159L8.58994 5.15745L8.33753 4.65264L6.99979 1.94351V10.0457L7.49619 10.3065L10.1717 11.72L9.66686 8.73317L9.5659 8.17788L9.97816 7.78245ZM13.781 5.57812L10.727 8.55649L11.4505 12.7632C11.4786 12.9483 11.4617 13.0927 11.4 13.1965C11.3383 13.3003 11.243 13.3522 11.114 13.3522C11.0186 13.3522 10.9064 13.3185 10.7774 13.2512L6.99979 11.2656L3.22215 13.2512C3.09314 13.3185 2.98096 13.3522 2.88561 13.3522C2.75661 13.3522 2.66125 13.3003 2.59955 13.1965C2.53786 13.0927 2.52103 12.9483 2.54907 12.7632L3.27263 8.55649L0.210131 5.57812C0.0306442 5.39864 -0.0338591 5.23177 0.0166217 5.07752C0.0671025 4.92328 0.218545 4.82652 0.470949 4.78726L4.69451 4.17308L6.58753 0.344952C6.69971 0.114984 6.83713 0 6.99979 0C7.15685 0 7.29427 0.114984 7.41205 0.344952L9.30508 4.17308L13.5286 4.78726C13.781 4.82652 13.9325 4.92328 13.983 5.07752C14.0334 5.23177 13.9661 5.39864 13.781 5.57812Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};
