import { useStyles } from './Container.style';

export type ContainerSize = 'md' | 'lg';

export interface ContainerProps {
  children?: React.ReactNode;
  className?: string;
  size?: 'md' | 'lg';
  noFullWidthPadding?: boolean;
}

export const Container = ({ children, className, size, noFullWidthPadding }: ContainerProps): React.ReactNode => {
  const { classes, cx } = useStyles({ noFullWidthPadding });

  if (!size) {
    return children;
  }

  return (
    <div
      className={cx(
        classes.container,
        size === 'md' && classes.md,
        size === 'lg' && classes.lg,
        className
      )}
    >
      {children}
    </div>
  );
};
