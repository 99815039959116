import { createStyles, keyframes } from '@mantine/core';

export const spin = keyframes({
  from: { transform: 'rotate(0deg)' },
  to: { transform: 'rotate(359deg)' }
});

interface StyleProps {
  color: string;
}

export const useStyles = createStyles((theme, { color }: StyleProps) => ({
  icon: {
    color
  },

  spinner: {
    display: 'inline-block',
    animation: `${spin} 1s infinite ease-in-out`
  }
}));
