export interface IconBurgerProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconBurger = ({
  color,
  size,
  ...props
}: IconBurgerProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 17 16" fill="none" {...props}>
      <path d="M0 0.656616H17V3.65662H0V0.656616Z" fill={color ?? 'currentColor'}/>
      <path d="M0 6.65662H17V9.65662H0V6.65662Z" fill={color ?? 'currentColor'}/>
      <path d="M0 12.6566H17V15.6566H0V12.6566Z" fill={color ?? 'currentColor'}/>
    </svg>
  );
};
