export interface IconStarProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconStar = ({
  color,
  size,
  ...props
}: IconStarProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 14 14" fill="none" {...props}>
      <path d="M14 5.17428C14 5.29768 13.9271 5.43229 13.7813 5.57812L10.7272 8.55649L11.4507 12.7632C11.4563 12.8025 11.4591 12.8586 11.4591 12.9315C11.4591 13.0493 11.4297 13.1488 11.3708 13.2302C11.3119 13.3115 11.2264 13.3522 11.1142 13.3522C11.0076 13.3522 10.8954 13.3185 10.7776 13.2512L7 11.2656L3.22236 13.2512C3.09896 13.3185 2.98678 13.3522 2.88582 13.3522C2.76803 13.3522 2.67969 13.3115 2.62079 13.2302C2.5619 13.1488 2.53245 13.0493 2.53245 12.9315C2.53245 12.8978 2.53806 12.8417 2.54928 12.7632L3.27284 8.55649L0.210337 5.57812C0.0701122 5.42668 0 5.29207 0 5.17428C0 4.96675 0.157051 4.83774 0.471154 4.78726L4.69471 4.17308L6.58774 0.344952C6.69431 0.114984 6.83173 0 7 0C7.16827 0 7.30569 0.114984 7.41226 0.344952L9.30529 4.17308L13.5288 4.78726C13.8429 4.83774 14 4.96675 14 5.17428Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};
