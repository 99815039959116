export const IconEdrLogo = (): React.ReactElement => (
  <svg xmlns="http://www.w3.org/2000/svg" width="68" height="31" viewBox="0 0 68 31" fill="none">
    <g clipPath="url(#clip0_291_671)">
      <path d="M58.7127 31H0.309576C0.138602 31 0 30.8612 0 30.69V9.3C0 4.16375 4.15805 0 9.28727 0H67.6904C67.8614 0 68 0.138791 68 0.31V21.7C68 26.8362 63.8419 31 58.7127 31Z" fill="#FD6400" />
      <path d="M16.3648 9.1961H13.4973C11.8805 9.1961 10.569 10.5029 10.5556 12.1189V9.76135C10.5556 9.43775 10.3131 9.19586 10.0178 9.19586H7.6263C7.33068 9.19586 7.0882 9.43775 7.0882 9.76135V21.6327C7.0882 21.9286 7.32994 22.1705 7.62507 22.1705H10.0188C10.3139 22.1705 10.5556 21.9286 10.5556 21.6327L10.5566 14.2828C10.5566 14.265 10.5578 14.2469 10.5603 14.2291C10.679 13.4242 11.0287 12.6911 11.5844 12.1164C12.3521 11.3236 13.4239 10.8871 14.6029 10.8871C14.6843 10.8871 14.7515 10.95 14.7579 11.0315C14.7636 11.1129 14.7063 11.185 14.6259 11.1964C14.6094 11.1989 12.995 11.4521 12.0691 12.7512C11.7596 13.1867 11.5604 13.6893 11.4714 14.2558H13.5954C15.2206 14.2558 16.5379 12.9364 16.5379 11.3085V9.36942C16.5379 9.27385 16.4605 9.1961 16.3648 9.1961Z" fill="white" />
      <path d="M23.1306 9.04834C21.5808 9.04834 20.4725 10.2454 20.4725 11.7446C20.4725 13.2437 21.5808 14.399 23.1615 14.399C23.6752 14.399 24.1053 14.2925 24.4711 14.1249C24.848 13.9459 25.1718 13.6857 25.3926 13.392C25.4568 13.3081 25.4568 13.1905 25.3394 13.118L24.5967 12.6565C24.4822 12.5948 24.3984 12.6255 24.3257 12.7094C24.1779 12.8773 24.041 12.9919 23.865 13.087C23.6752 13.1821 23.4547 13.2437 23.1838 13.2437C22.5023 13.2437 21.9382 12.7711 21.8629 12.0605H25.4652C25.6551 12.0605 25.708 11.9543 25.708 11.8285V11.5767C25.708 10.234 24.6916 9.04834 23.1306 9.04834ZM21.8965 11.1991C22.0304 10.5586 22.5332 10.139 23.1418 10.139C23.7172 10.139 24.2504 10.5084 24.3762 11.1991H21.8965Z" fill="white" />
      <path d="M27.4196 14.2512C27.273 14.2512 27.1786 14.1569 27.1368 14.0311L25.5348 9.52126C25.472 9.35339 25.5662 9.19617 25.7338 9.19617H26.7495C26.9064 9.19617 26.9694 9.2905 27.0112 9.40612L28.1525 12.8773L29.1995 9.40612C29.2306 9.2905 29.3041 9.19617 29.4613 9.19617H30.4559C30.6237 9.19617 30.7179 9.35339 30.6655 9.52126L29.0633 14.0311C29.0319 14.1569 28.9274 14.2512 28.7912 14.2512H27.4196Z" fill="white" />
      <path d="M33.0738 9.04834C31.527 9.04834 30.4156 10.2454 30.4156 11.7446C30.4156 13.2437 31.527 14.399 33.1074 14.399C33.6184 14.399 34.0484 14.2925 34.4143 14.1249C34.7912 13.9459 35.118 13.6857 35.3358 13.392C35.4 13.3081 35.4 13.1905 35.2853 13.118L34.5398 12.6565C34.4254 12.5948 34.3416 12.6255 34.2689 12.7094C34.1211 12.8773 33.9869 12.9919 33.8082 13.087C33.6184 13.1821 33.4006 13.2437 33.127 13.2437C32.4455 13.2437 31.8814 12.7711 31.809 12.0605H35.4111C35.5983 12.0605 35.6512 11.9543 35.6512 11.8285V11.5767C35.6512 10.234 34.6347 9.04834 33.0738 9.04834ZM31.8397 11.1991C31.9766 10.5586 32.4791 10.139 33.0849 10.139C33.6604 10.139 34.1965 10.5084 34.3221 11.1991H31.8397Z" fill="white" />
      <path d="M55.8012 15.7628C55.7068 15.6685 55.6962 15.5113 55.7906 15.3855L56.1154 14.9344C56.241 14.7668 56.4187 14.7876 56.5445 14.8819C56.7227 15.0183 56.9318 15.1233 57.1832 15.1233C57.5077 15.1233 57.7488 14.9032 57.8639 14.5885L57.9846 14.2511H57.5137C57.3772 14.2511 57.2937 14.1671 57.2517 14.0416L55.7231 9.52112C55.6811 9.35325 55.7125 9.19629 55.9426 9.19629H57.0213C57.1471 9.19629 57.22 9.30077 57.2517 9.41639L58.3511 13.024L59.3875 9.41639C59.4189 9.30077 59.5027 9.19629 59.6597 9.19629H60.6964C60.885 9.19629 60.9477 9.33246 60.885 9.52112L59.1935 14.798C58.9004 15.6999 58.2723 16.2872 57.2774 16.2872C56.6807 16.2872 56.1468 16.0884 55.8012 15.7628Z" fill="white" />
      <path d="M55.142 9.19634H54.2178C54.1145 9.19634 54.0111 9.29141 54.0111 9.41743V9.81457C53.7598 9.37534 53.1819 9.04828 52.4697 9.04828C51.1404 9.04828 49.9369 10.0886 49.9369 11.7138C49.9369 13.3387 51.1404 14.3989 52.4613 14.3989C53.1735 14.3989 53.7486 14.0716 54.0111 13.6324V14.0409C54.0111 14.1555 54.1031 14.2506 54.2178 14.2506H55.142C55.2564 14.2506 55.3513 14.1555 55.3513 14.0409V9.41743C55.3513 9.29141 55.2564 9.19634 55.142 9.19634ZM52.6596 13.1486C51.8832 13.1486 51.2969 12.5108 51.2969 11.722C51.2969 10.9361 51.8832 10.3068 52.6596 10.3068C53.433 10.3068 54.0111 10.9361 54.0111 11.722C54.0111 12.5108 53.433 13.1486 52.6596 13.1486Z" fill="white" />
      <path d="M49.3753 7.29456H48.4511C48.3478 7.29456 48.2445 7.38963 48.2415 7.51269L48.2304 9.7754C48.1745 9.68305 48.1016 9.59565 48.0153 9.51469C47.7103 9.22798 47.2301 9.02867 46.6638 9.02867C45.3515 9.02867 44.1507 10.0831 44.1507 11.7026C44.1507 13.336 45.3515 14.3989 46.6863 14.3989C47.4011 14.3989 47.9456 14.0716 48.2415 13.6296V14.0409C48.2415 14.1555 48.3364 14.2506 48.4511 14.2506H49.3642C49.4925 14.2506 49.5847 14.1555 49.5847 14.0409V12.1389V7.51541V7.51269C49.582 7.38963 49.4786 7.29456 49.3753 7.29456ZM46.8845 13.1458C46.1054 13.1458 45.5191 12.5026 45.5191 11.7138C45.5191 10.925 46.1054 10.2929 46.8845 10.2929C47.6636 10.2929 48.2415 10.925 48.2415 11.7138C48.2415 12.5026 47.6636 13.1458 46.8845 13.1458Z" fill="white" />
      <path d="M39.5792 9.19623H38.4619C37.8321 9.19623 37.3209 9.70528 37.3157 10.3349V9.41634C37.3157 9.29031 37.2213 9.19623 37.1064 9.19623H36.1745C36.0593 9.19623 35.9649 9.29031 35.9649 9.41634V14.0415C35.9649 14.1569 36.0591 14.2513 36.174 14.2513H37.1066C37.2218 14.2513 37.3157 14.1569 37.3157 14.0415L37.3162 11.1782C37.3162 11.171 37.3167 11.1641 37.3177 11.1571C37.3639 10.8434 37.5001 10.558 37.7167 10.3339C38.0157 10.0252 38.4332 9.85506 38.8927 9.85506C38.9244 9.85506 38.9506 9.87957 38.9531 9.91126C38.9553 9.94296 38.933 9.97118 38.9016 9.97564C38.8952 9.97663 38.2661 10.0752 37.9055 10.5812C37.7849 10.7511 37.7073 10.9469 37.6727 11.1675H38.5002C39.1332 11.1675 39.6466 10.6535 39.6466 10.0192V9.26382C39.6466 9.22643 39.6165 9.19623 39.5792 9.19623Z" fill="white" />
      <path d="M44.0865 9.19617H43.0503C42.8929 9.19617 42.8093 9.3009 42.778 9.41628L41.7413 13.0241L40.6418 9.41628C40.6104 9.3009 40.5373 9.19617 40.4117 9.19617H40.0706V10.0194C40.0706 10.4344 39.908 10.8114 39.6447 11.0929L40.6418 14.0415C40.6839 14.1673 40.7676 14.2512 40.9038 14.2512H41.375L41.2538 14.5884C41.1389 14.9031 40.8979 15.1232 40.5736 15.1232C40.322 15.1232 40.1129 15.0182 39.9346 14.882C39.8093 14.7877 39.6311 14.7667 39.5055 14.9343L39.1808 15.3854C39.0863 15.5114 39.0972 15.6684 39.1914 15.7627C39.5367 16.0883 40.0706 16.2874 40.6675 16.2874C41.6624 16.2874 42.2905 15.7001 42.5837 14.7979L44.2751 9.52126C44.3379 9.33235 44.2751 9.19617 44.0865 9.19617Z" fill="white" />
      <path d="M36.6029 17.0824H35.7235C35.5668 17.0824 35.4933 17.1873 35.4619 17.2918L34.5512 20.5208L33.6826 17.2918C33.6408 17.145 33.5464 17.0824 33.4108 17.0824H32.3219C32.1861 17.0824 32.1025 17.145 32.0603 17.2918L31.1918 20.5208L30.3021 17.2918C30.2706 17.1765 30.1974 17.0824 30.0404 17.0824H29.0569C28.889 17.0824 28.7951 17.1977 28.8576 17.3864L30.3125 21.915C30.3547 22.0409 30.4487 22.1352 30.5849 22.1352H31.7568C31.893 22.1352 31.9977 22.0409 32.0292 21.915L32.8349 18.8853L33.6408 21.915C33.6722 22.0409 33.777 22.1352 33.9236 22.1352H35.0642C35.2108 22.1352 35.3049 22.0409 35.3468 21.915L36.8016 17.3864C36.8646 17.1977 36.7702 17.0824 36.6029 17.0824Z" fill="white" />
      <path d="M39.4096 21.0342C38.6351 21.0342 38.0489 20.3947 38.0489 19.6083C38.0489 18.822 38.6351 18.1933 39.4096 18.1933C40.1842 18.1933 40.7597 18.822 40.7597 19.6083C40.7597 20.3947 40.1842 21.0342 39.4096 21.0342ZM41.8902 17.082H40.9692C40.8645 17.082 40.7597 17.1762 40.7597 17.3023V17.7004C40.5084 17.26 39.9329 16.9352 39.2214 16.9352C37.892 16.9352 36.6884 17.9733 36.6884 19.5979C36.6884 21.2229 37.892 22.2815 39.2108 22.2815C39.9226 22.2815 40.498 21.9565 40.7597 21.5165V21.9254C40.7597 22.0405 40.8541 22.1349 40.9692 22.1349H41.8902C42.0053 22.1349 42.0993 22.0405 42.0993 21.9254V17.3023C42.0993 17.1762 42.0053 17.082 41.8902 17.082Z" fill="white" />
      <path d="M54.4451 19.0842L53.6191 18.9272C53.1592 18.8435 52.9815 18.6865 52.9815 18.4454C52.9815 18.2047 53.2744 17.9953 53.7343 17.9953C54.1734 17.9953 54.6023 18.1943 54.9261 18.4247C55.1143 18.5398 55.2715 18.5398 55.3968 18.3932L55.7314 17.9953C55.8462 17.8592 55.8673 17.7019 55.7211 17.5764C55.3968 17.2934 54.7276 16.9376 53.7657 16.9376C52.4691 16.9376 51.6328 17.6394 51.6328 18.5502C51.6328 19.3672 52.1762 19.8805 52.9919 20.0477L53.797 20.2155C54.3198 20.3203 54.6023 20.4247 54.6023 20.718C54.6023 20.9798 54.3409 21.2209 53.797 21.2209C53.264 21.2209 52.8455 20.9906 52.5109 20.6973C52.3855 20.5925 52.2391 20.5088 52.0406 20.6658L51.6534 20.9798C51.4448 21.1476 51.4237 21.3883 51.8108 21.6816C52.2077 21.9851 52.8562 22.2784 53.7343 22.2784C55.0622 22.2784 55.9823 21.5665 55.9823 20.5925C55.9823 19.6812 55.3339 19.252 54.4451 19.0842Z" fill="white" />
      <path d="M26.4315 18.0256C25.8242 18.0256 25.3218 18.4449 25.1858 19.0845H27.6668C27.5411 18.3924 27.0073 18.0256 26.4315 18.0256ZM28.9961 19.4619V19.7137C28.9961 19.8394 28.9439 19.9443 28.7554 19.9443H25.1542C25.2276 20.6572 25.793 21.129 26.4733 21.129C26.7456 21.129 26.9653 21.0661 27.1538 20.9718C27.3317 20.8775 27.4679 20.7622 27.6144 20.5944C27.6876 20.5104 27.7714 20.479 27.8865 20.5419L28.6298 21.0033C28.745 21.0765 28.745 21.1919 28.6822 21.2758C28.4623 21.5694 28.1378 21.8315 27.761 22.0097C27.3945 22.1775 26.9653 22.2825 26.4525 22.2825C24.8717 22.2825 23.7622 21.129 23.7622 19.6298C23.7622 18.1303 24.8718 16.935 26.4211 16.935C27.9807 16.935 28.9961 18.1199 28.9961 19.4619Z" fill="white" />
      <path d="M23.5209 17.0189C23.6255 17.0189 23.7616 17.0713 23.7616 17.281V18.1827C23.7616 18.3924 23.5941 18.4448 23.5105 18.4448H23.2592C22.5893 18.4448 22.2021 18.7909 22.2021 19.6611V21.9364C22.2021 22.0519 22.0869 22.1356 21.9822 22.1356H21.0609C20.9458 22.1356 20.8516 22.0413 20.8516 21.926V17.302C20.8516 17.1763 20.9458 17.0819 21.0609 17.0819H21.9298C22.0345 17.0819 22.1391 17.1763 22.1391 17.281V17.7634C22.4008 17.3124 22.8406 17.0189 23.3744 17.0189H23.5209Z" fill="white" />
      <path d="M49.9227 15.1859C49.8185 15.1859 49.7097 15.2788 49.7076 15.4033V17.6661C49.4553 17.2447 48.8614 16.9187 48.1365 16.9187C46.8231 16.9187 45.6251 17.9713 45.6251 19.592C45.6251 21.2236 46.8231 22.2864 48.1577 22.2864C48.8722 22.2864 49.4182 21.9602 49.7125 21.5184V21.9289C49.7125 22.0445 49.8073 22.1392 49.9229 22.1392H50.837C50.9633 22.1392 51.0575 22.0445 51.0575 21.9289L51.0575 15.4035C51.0555 15.2789 50.9521 15.1861 50.848 15.1861L49.9227 15.1859ZM48.3572 21.0341C47.5795 21.0341 46.9911 20.3921 46.9911 19.6025C46.9911 18.813 47.5795 18.1818 48.3572 18.1818C49.1347 18.1818 49.7125 18.813 49.7125 19.6025C49.7125 20.3921 49.1347 21.0341 48.3572 21.0341Z" fill="white" />
      <path d="M45.3809 17.0184C45.4854 17.0184 45.6216 17.0708 45.6216 17.2805V18.1822C45.6216 18.3919 45.454 18.4443 45.3705 18.4443H45.1192C44.4492 18.4443 44.062 18.7904 44.062 19.6607V21.9359C44.062 22.0514 43.9468 22.1351 43.8421 22.1351H42.9209C42.8057 22.1351 42.7115 22.0408 42.7115 21.9255V17.3015C42.7115 17.1758 42.8057 17.0815 42.9209 17.0815H43.7897C43.8945 17.0815 43.999 17.1758 43.999 17.2805V17.7629C44.2607 17.3119 44.7005 17.0184 45.2343 17.0184H45.3809Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_291_671">
        <rect width="68" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
