export interface IconXProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconX = ({
  color,
  size,
  ...props
}: IconXProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 11 12" fill="none" {...props}>
      <path d="M7.585 6L10.7122 2.87281C11.0959 2.48906 11.0959 1.86687 10.7122 1.48281L10.0172 0.787813C9.63344 0.404063 9.01125 0.404063 8.62719 0.787813L5.5 3.915L2.37281 0.787813C1.98906 0.404063 1.36688 0.404063 0.982813 0.787813L0.287813 1.48281C-0.0959375 1.86656 -0.0959375 2.48875 0.287813 2.87281L3.415 6L0.287813 9.12719C-0.0959375 9.51094 -0.0959375 10.1331 0.287813 10.5172L0.982813 11.2122C1.36656 11.5959 1.98906 11.5959 2.37281 11.2122L5.5 8.085L8.62719 11.2122C9.01094 11.5959 9.63344 11.5959 10.0172 11.2122L10.7122 10.5172C11.0959 10.1334 11.0959 9.51125 10.7122 9.12719L7.585 6Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};
