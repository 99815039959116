export interface IconEdrLogoSmallProps extends React.SVGAttributes<SVGElement> {
  size?: number;
}

export const IconEdrLogoSmall = ({
  size,
  ...props
}: IconEdrLogoSmallProps): React.ReactElement => {
  const defaultSize = 14;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 22 14" fill="none" {...props}>
      <path d="M0.165 14C0.11 14 0 13.9449 0 13.8346V4.18898C0 1.87402 1.87 0 4.18 0H21.835C21.945 0 22 0.055118 22 0.165354V9.81102C22 12.126 20.13 14 17.82 14H0.165Z" fill="#FD6400" />
      <path d="M13.767 3H12.0194C11.0291 3 10.2136 3.81159 10.2136 4.7971V3.34783C10.2136 3.17391 10.0388 3 9.86408 3H9.1068H8.34951C8.17476 3 8 3.17391 8 3.34783V10.6522C8 10.8261 8.17476 11 8.34951 11H9.1068H9.86408C10.0388 11 10.2136 10.8261 10.2136 10.6522V6.13043C10.2136 6.13043 10.2136 6.13043 10.2136 6.07246C10.2718 5.55072 10.5049 5.14493 10.8544 4.7971C11.3204 4.33333 12.0194 4.04348 12.7184 4.04348C12.7767 4.04348 12.835 4.10145 12.835 4.15942C12.835 4.21739 12.7767 4.27536 12.7767 4.27536C12.7767 4.27536 11.7864 4.44928 11.2039 5.2029C11.0291 5.49275 10.9126 5.78261 10.8544 6.13043H12.1942C13.1845 6.13043 14 5.31884 14 4.33333V3.11594C14 3 13.8252 3 13.767 3Z" fill="white" />
    </svg>
  );
};
