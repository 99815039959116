export interface IconSearchProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconSearch = ({
  color,
  size,
  ...props
}: IconSearchProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 20 21" fill="none" {...props}>
      <path d="M19.728 17.4479L15.8332 13.5537C15.6574 13.378 15.4191 13.2803 15.1691 13.2803H14.5323C15.6105 11.9016 16.2512 10.1674 16.2512 8.28082C16.2512 3.79298 12.6142 0.156616 8.12559 0.156616C3.63698 0.156616 0 3.79298 0 8.28082C0 12.7687 3.63698 16.405 8.12559 16.405C10.0125 16.405 11.747 15.7645 13.126 14.6864V15.3231C13.126 15.5731 13.2236 15.8113 13.3994 15.9871L17.2942 19.8813C17.6614 20.2484 18.2552 20.2484 18.6185 19.8813L19.7241 18.7759C20.0913 18.4087 20.0913 17.815 19.728 17.4479ZM8.12559 13.2803C5.36367 13.2803 3.12523 11.0462 3.12523 8.28082C3.12523 5.51937 5.35977 3.28131 8.12559 3.28131C10.8875 3.28131 13.126 5.51547 13.126 8.28082C13.126 11.0423 10.8914 13.2803 8.12559 13.2803Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};
