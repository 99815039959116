import { IconLoader2 } from '@tabler/icons-react';

import { useStyles } from './IconSpinner.style';

export interface IconSpinnerProps {
  color: string;
  isSpinning?: boolean;
}

export const IconSpinner = ({ color, isSpinning }: IconSpinnerProps): React.ReactElement => {
  const { classes, cx } = useStyles({ color });
  return (
    <IconLoader2 className={cx(classes.icon, isSpinning && classes.spinner)} />
  );
};
