export interface IconPurchaseProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconPurchase = ({
  color,
  size,
  ...props
}: IconPurchaseProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 11 13" {...props}>
      <path d="M964.21 190H954.7900000000001C954.3100000000001 190 954.0000000000001 190.31 954.0000000000001 190.79V201.79C954.0000000000001 202.26 954.3100000000001 202.57 954.7900000000001 202.57H964.21C964.69 202.57 965 202.26 965 201.79V190.79C965 190.31 964.69 190 964.21 190ZM962.64 193.93C962.64 195.66 961.23 197.07 959.5 197.07C957.77 197.07 956.36 195.66 956.36 193.93V193.14000000000001H957.9300000000001V193.93C957.9300000000001 194.79000000000002 958.6400000000001 195.5 959.5000000000001 195.5C960.3600000000001 195.5 961.0700000000002 194.79 961.0700000000002 193.93V193.14000000000001H962.6400000000002Z " fill={color ?? 'currentColor'} transform="matrix(1,0,0,1,-954,-190)" />
    </svg>
  );
};
