export interface IconCartProps extends React.SVGAttributes<SVGElement> {
  color?: string;
  size?: number;
}

export const IconCart = ({
  color,
  size,
  ...props
}: IconCartProps): React.ReactElement => {
  const defaultSize = 16;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={size ?? defaultSize} viewBox="0 0 20 21" fill="none" {...props}>
      <path d="M18.73 3.90662H5.62L4.98999 1.15662C4.86999 0.526616 4.37 0.156616 3.75 0.156616H0V2.65662H2.75L4.98999 12.8866C5.11999 13.5166 5.61999 13.8866 6.23999 13.8866H16.23C16.73 13.8866 17.23 13.5166 17.35 13.0166L19.85 5.52662C20.1 4.89662 19.73 3.90662 18.73 3.90662Z" fill={color ?? 'currentColor'} />
      <path d="M4 17.6566C4 16.2759 5.11929 15.1566 6.5 15.1566C7.88071 15.1566 9 16.2759 9 17.6566C9 19.0373 7.88071 20.1566 6.5 20.1566C5.11929 20.1566 4 19.0373 4 17.6566Z" fill={color ?? 'currentColor'} />
      <path d="M14 17.6566C14 16.2759 15.1193 15.1566 16.5 15.1566C17.8807 15.1566 19 16.2759 19 17.6566C19 19.0373 17.8807 20.1566 16.5 20.1566C15.1193 20.1566 14 19.0373 14 17.6566Z" fill={color ?? 'currentColor'} />
    </svg>
  );
};
