import React, { useState } from 'react';

import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { useStyles } from './NewsletterSection.style';

import { Container } from '@/components/Container';
import { IconSpinner } from '@/components/Icons';
import { PartialWrapper } from '@/components/PartialWrapper';
import { newsletterSubscription } from '@/services/newsletter';

declare let mdsf: any;
declare let fbq: any;

export interface NewsletterSectionProps {
  title: string;
}

export interface NewsletterFormProps {
  title: string;
  handleSignUpSuccess;
}

const NewsletterForm = ({ title, handleSignUpSuccess }: NewsletterFormProps): React.ReactElement => {
  const [isBusy, setIsBusy] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [error, setError] = useState('');
  const theme = useMantineTheme();
  const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  const { classes } = useStyles();

  const handleEmailAddressChange = (e: React.ChangeEvent<HTMLInputElement>): void => setEmailAddress(e.target.value);

  const onSubmit = async (e: React.FormEvent): Promise<void> => {
    const formData = { email: emailAddress, description: title };

    e.preventDefault();
    setIsBusy(true);

    try {
      const response = await newsletterSubscription('/account/subscribetonewsletter', formData);

      if (response.ok) {
        handleSignUpSuccess(true);
        mdsf.setUserInfo(formData.email);
        mdsf.trackPageViewBlank();
        fbq('track', 'CompleteRegistration');
      }
      else if (response.status === 400) {
        setError('Please enter a valid email address');
      }
    }

    catch (error) {
      setError('Something happened, please try again later.');
    }

    finally {
      setIsBusy(false);
    }
  };

  return (
    <div className={classes.newsletterForm}>
      <h2 className={classes.title}>{title}</h2>
      <form onSubmit={onSubmit}>
        <div className={classes.newsletterFormWrap}>
          <input
            type='email'
            required
            aria-label="email"
            placeholder="Email address"
            value={emailAddress}
            className={classes.emailInput}
            onChange={handleEmailAddressChange}
          />
          <button className={classes.btnSubmit} type="submit" id="btnSubmit">
            {!isBusy ? (mobile ? 'Get $10' : 'Get $10 code') : <IconSpinner color='#ffffff' isSpinning={true} />}
          </button>
        </div>
        {!!error &&
          <div className={classes.validationError}>
            {error}
          </div>
        }
        <div className={classes.termText}>
          *$100 min spend (excluding shipping). New subscribers only.
        </div>
      </form>
    </div>
  );
};

const SuccessMessage = (): React.ReactNode => {
  const { classes } = useStyles();

  return (
    <div className={classes.successMessage}>
      <h2 className={classes.successHeading}>
        <span>Thank you!</span>
        You&apos;ll receive an email with your unique $10 coupon code.
      </h2>
      <span> Just enter the coupon code at checkout!</span>
    </div>
  );
};

export const NewsletterSection = ({ title }: NewsletterSectionProps): React.ReactElement => {
  const { classes } = useStyles();
  const [signUpSuccess, setSignUpSuccess] = useState(false);

  const handleSignUpSuccess = (signupState: boolean): void => {
    setSignUpSuccess(signupState);
  };

  return (
    <section className={classes.newsletterSignup}>
      <Container size='md'>
        {!signUpSuccess ? <NewsletterForm title={title} handleSignUpSuccess={handleSignUpSuccess} /> : <SuccessMessage />}
      </Container>
    </section>

  );
};

export const NewsletterSectionWrapped = (props: NewsletterSectionProps): React.ReactElement => (
  <PartialWrapper>
    <NewsletterSection {...props} />
  </PartialWrapper>
);
